/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useCallback, useState } from 'react';
import { format } from 'date-fns';
import Panel, { PanelHeader } from '../../ui/Panel';
import Circle from '../../ui/Circle';
import { CalendarIcon, ClientIcon } from '../../ui/icons';
import ScanResult, { AnyWithWrongClient, Cbc2ndMtgMsg, DocNotUploaded, Error } from './ScanResults';
import colors from '../../../styles/colors';
import { usePostData } from '../../../Hooks';
import BarcodeInput from '../../Utilities/BarcodeInput';
import { Client, Loan } from '../../../globalTypes/objects';
import { IconType } from '../../ui/Icon';
import useCheckIfDocTypeUpdated from '../../../Hooks/useCheckIfDocTypeUpdated';
import { isSuccess, mapSuccess } from '../../../globalTypes/RemoteData';
import { getDocTypeName } from '../../../Utils';

type SortDocumentPanelProps = {
  onChange: (docId: number) => void;
  client?: Client;
};

export type DocData = {
  client: string;
  dateDocumentsDrawn: Date;
  loan: Loan;
  investor: string;
  failedVerification: boolean;
  failedAudit: boolean;
  audited: boolean;
  corrected: boolean;
  replacedWith?: number;
  hardCopy: boolean;
  docUploaded: boolean;
  shouldBeShredded: boolean;
  active: boolean;
  cbcSecondMtg: boolean;
  flaggedDocumentId?: number;
  isDigitalDeliveryShred: boolean;
  isDigitalDeliveryThatNeedsCorrection: boolean;
};

export type AlertType = 'PRIMARY' | 'SUCCESS' | 'WARNING' | 'ERROR';

export type SortResponse = {
  sortDocumentLocation: DocData | null;
  message: string;
  additionalInformation: string;
  fullResult: boolean;
  alertType: AlertType;
  compartmentId?: number;
  compartmentLabel?: string;
  isSorted: boolean;
  requiresPrinterId: boolean;
};

export default function SortDocumentPanel({ onChange, client }: SortDocumentPanelProps) {
  const [documentIdObj, setDocumentIdObj] = useState({ int: undefined as unknown as number });

  const docTypeUpdated = useCheckIfDocTypeUpdated(documentIdObj.int);

  const { data, isLoading, error, doPost } = usePostData<SortResponse>();

  const docTypeUpdatedMessage = mapSuccess(docUpdatedResult => {
    if (docUpdatedResult.updated) {
      return `This document has had its DocType changed to ${getDocTypeName(
        docUpdatedResult.documentType,
      )}.
      Please ensure the coversheet is correct.`;
    }
    return '';
  }, docTypeUpdated);

  const handleDocIdChange = useCallback(
    async value => {
      if (!value) {
        return;
      }

      setDocumentIdObj({ int: parseInt(value, 10) });
      doPost('/api/documents/sortDoc', {
        documentId: value,
        isNewSort: false,
      });
      onChange(documentIdObj.int);
    },
    [doPost, documentIdObj.int, onChange],
  );
  return (
    <Panel>
      <PanelHeader
        text="Sort Documents"
        iconComponent={<Circle icon={IconType.Docs} styleoverrides={{ color: colors.blue }} />}
      />

      <BarcodeInput onChange={handleDocIdChange} keepFocus />

      {data && !error && !isLoading && (
        <Fragment>
          {data.fullResult ? (
            <FullResult sortResponse={data} client={client} />
          ) : (
            <ScanResult
              customStyles={{ div: { color: 'red' } }}
              headerMessage={data.message}
              message={data.additionalInformation}
            />
          )}
          {isSuccess(docTypeUpdatedMessage) && docTypeUpdatedMessage.data && (
            <ScanResult headerMessage={docTypeUpdatedMessage.data} customStyles={{}} />
          )}
        </Fragment>
      )}
      {error && <Error />}
    </Panel>
  );
}

// #region results section

const tabsWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 24,
};

const tabStyle = {
  alignItems: 'center',
  backgroundColor: colors.grayExLight,
  borderRadius: 4,
  color: colors.blue,
  display: 'inline-flex',
  fontSize: 18,
  fontWeight: 600,
  height: 72,
  justifyContent: 'center',
  padding: '8px 16px',
  width: 'calc(100% - 224px)',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
};

const getMessage = (additionalInformation: string, alertType: AlertType, investor: string) => {
  if (!additionalInformation && alertType === 'SUCCESS') {
    return investor;
  }

  return additionalInformation;
};

const sortResultFull = ({
  sortDocumentLocation,
  message,
  additionalInformation,
  alertType,
}: SortResponse) => (
  <Fragment>
    <ScanResult
      headerMessage={message}
      message={getMessage(additionalInformation, alertType, sortDocumentLocation.investor)}
    />
    {sortDocumentLocation?.cbcSecondMtg && <Cbc2ndMtgMsg />}
  </Fragment>
);

type FullResultProps = {
  sortResponse: SortResponse;
  client?: Client;
};

function FullResult({ sortResponse, client }: FullResultProps) {
  const Result = sortResultFull(sortResponse);
  const docData = sortResponse.sortDocumentLocation;

  return (
    <Fragment>
      {client?.company !== docData.client && <AnyWithWrongClient />}
      <div css={{ marginTop: 16 }}>{Result}</div>
      <div css={tabsWrap}>
        <div css={tabStyle}>
          <ClientIcon />
          <span css={{ color: colors.blackText, marginLeft: 8 }}>{docData.client}</span>
        </div>
        <div css={[tabStyle, { width: 210 }]}>
          <CalendarIcon />
          <span css={{ color: colors.blackText, marginLeft: 8 }}>
            {format(new Date(Date.parse(docData.dateDocumentsDrawn.toString())), 'MMMM yyyy')}
          </span>
        </div>
      </div>
      {!docData.docUploaded && <DocNotUploaded />}
    </Fragment>
  );
}

// #endregion
