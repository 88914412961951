import { ReactNode } from 'react';
import { MatchData } from '../components/Documents/MatchVerification';
import { LoanInformation } from '../components/Loans/types';

export type Loan = {
  id: number;
  clientID: number;
  client: Client;
  mTANum: string;
  loanNumber: string;
  borrower: string;
  propertyAddress: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  investorID: number;
  titleCompanyID: number;
  dateDocumentsDrawn: Date;
  loanAmount: number;
  isCoop: boolean;
  uploadedby: string;
  servicerName: string;
  servicerLoanNum: string;
  isManuallyClosed: boolean;
  documents?: Document[];
};

export type LoanDocument = {
  id: number;
  loanId: number;
  documentTypeId: number;
  isRequired: boolean;
  investorId: number;
  createdAt: string;
};

export type Client = {
  id: number;
  company: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  fax: string;
  email: string;
  invoiceEmail: string;
  invoiceEmailCC: string;
  startDate: Date;
  accountRep?: string;
};

export type Investor = {
  id: number;
  name: string;
  contact: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  mailcode: string;
  phone: string;
  email: string;
  requiresOriginalDoc: string;
  coverletterBasePath: string;
  manifestMaximum: number | undefined;
};

export type TitleCompany = {
  id: number;
  name: string;
  contact: string;
  phone: string;
  fax: string;
  email: string;
  address: string;
  sendToGlobal: boolean;
  verifiedAt: Date | undefined;
};

export type Document = {
  id: number;
  active: boolean;
  loanId: number | null;
  fileBasePath: string;
  fileSubpath: string;
  documentType: DocumentType | null;
  documentTypeName: string;
  chargeId: number;
  hardCopy: boolean;
  documentsId: number;
  loanNumber: string;
  dateDocumentsDrawn: Date;
  createdAt: Date;
  isSorted?: boolean;
  clientId?: number;
  isCorrection: boolean;
  propertyStreet?: string;
  isCopy: boolean;
};

export type DocumentEmail = {
  id: number;
  emailFrom: string;
  emailSubject: string;
};

export type DocumentEmailAttachment = {
  id: number;
  documentId?: number;
  isLogo: boolean;
  ignoreAttachment: boolean;
  filePath: string;
  documentType?: string;
  active: boolean;
};

export type TitleCompanyUpload = {
  id: number;
  email: string;
  loanNumber: string;
  borrowerName: string;
  dateFunded: string;
  generalUpload: boolean;
};

export type FileMetadata = {
  id: number;
  documentId: number;
  md5Hash: string;
  fileSize: string;
  originalFilename: string;
};

export interface AdditionalFee {
  Id: number;
  DocumentType: DocumentType;
  CostIncurred: number;
  LoanId?: number;
  LoanNumber: string;
  ClientId?: number;
}

export interface FlaggedDoc {
  id: number;
  client: Client;
  clientId: number;
  documentType: DocumentType;
  loanNumber: string;
  loanId: number;
  reason: string;
  address: string;
  borrower: string;
  createdAt: Date;
  createdBy?: string;
}

export enum DocumentType {
  Unknown = -1,
  AffidavitOfCorrection = 1,
  AffixationAffidavit = 2,
  Assignment = 3,
  CEMA = 4,
  CORR = 5,
  Deed = 6,
  Endorsement = 7,
  FirstMortgage = 8,
  SecondMortgage = 9,
  HECM = 10,
  Misc = 11,
  Mortgage = 12,
  POA = 13,
  Policy = 14,
  ReTransferAffidavit = 15,
  Subordination = 17,
  UCC = 18,
  UCC3 = 19,
  MIC = 20,
  UnrecordedDoc = 21,
  PolicyToSecondMortgage = 43,
  ModificationAgreement = 44,
  ModificationEndorsement = 45,
  RecordedReconveyance = 46,
  LetterReport = 47,
}

export enum DocumentStatus {
  NotFound = 0,
  Unsorted = 1,
  Found = 2,
  FailedAudit = 3,
}

export type DocumentTypePrediction = {
  prediction: DocumentType;
  probability: number;
};

export function assertNever(x: never, msg: string = `Unknown ${x}`): never {
  throw new Error(msg);
}

export enum ContactType {
  Investor = 'investorError',
  TitleCompany = 'titleError',
}
export type ContactInformation = {
  id?: number;
  firstAddress?: string;
  secondAddress?: string;
  address: string;
  city: string;
  state: string;
  name: string;
  zip: string;
  phone: string;
  fax: string;
  email: string;
  contact?: string;
  type: ContactType;
  requiresOriginalDoc: boolean;
  sendToGlobal: boolean;
  additionalEmail: string;
};

export type Note = {
  id: number;
  note: string;
  systemGenerated?: boolean;
  createdAt: Date;
  user: string;
};

export enum AlertSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export type Alert = {
  isOpen: boolean;
  message: string | ReactNode;
  severity: AlertSeverity;
};

export interface DocumentVerificationMatch {
  id: number;
  documentId: number;
  loanId: number;
  score: number;
  displayAt?: Date;
  matchData: string;
  matchDataObj: MatchData | undefined;
  matchedAt?: Date;
  resolvedBy?: string;
  active: boolean;
}

export interface TrueNotFounds {
  id: number;
  documentId: number;
  createdBy: string;
  createdAt: Date;
}

export interface DocumentAudit {
  id: number;
  documentId: number;
  passed: boolean;
  notes: string;
  failureReasons: AuditPoint[];
  resolvedBy: string | null;
  resolvedAt: string | null;
  isCorrected: boolean | null;
  sensitiveTier: number | null;
}

export interface FailedAuditReason {
  id: number;
  description: string;
  documentType: DocumentType;
}

export interface AuditPointOption {
  id: number;
  optionDescription: string;
  notesRequired: boolean;
  auditPointId: number;
  notes: string | null;
  kind: 'AuditPointOption';
  priority: number | null;
}

export interface AuditPoint {
  id: number;
  categoryId: number;
  descriptionId: number;
  documentType: DocumentType;
  category: string;
  description: string;
  options: AuditPointOption[];
  notes: string | null;
  notesRequired: boolean;
  investorOnly: boolean;
  kind: 'AuditPoint';
  priority: number | null;
}

export interface AuditPointCategory {
  id: number;
  categoryName: string;
  auditPoints: AuditPoint[];
  priority: number | null;
}

export interface CustomAuditRules {
  clientId: number;
  conditionType: string;
  conditionValue: string;
  requiredAddendaFormId: number;
  exceptionType: string;
  exceptionValue: string;
  docTypeId: number;
  policyEndorsement: number;
}

export interface FailedVerification {
  id: number;
}

export interface Verification {
  documentId: number;
  note: string;
  failedVerification: FailedVerification;
}

export interface RescanRequest {
  id: number;
  documentId: number;
  createdOn: string;
  createdBy: string;
}
export type ConfirmModal = {
  isOpen: boolean;
  header: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  cancelDisabled?: boolean;
  handleCancel?: (...args: any[]) => any;
  handleConfirm?: (...args: any[]) => any;
};

export interface DocumentPageDto {
  document: Document & { documentType: DocumentType; clientId: number };
  pdf: string;
  link: string | null;
  audit?: DocumentAudit;
  rescanRequest: RescanRequest;
  loanInformation?: LoanInformation;
  verificationFailures?: string[];
  verificationNote?: string;
  passedStandardAutomatedAudit: boolean | null;
}

export enum ClientReturnAddress {
  OceanAvenue1125 = 1,
  OceanAvenue1133 = 2,
  SwarthmoreAvenue1820 = 3,
  ChambersAvenue160 = 4,
}

export type TitleCompanyContact = {
  id?: number;
  firstName: string;
  lastName: string;
  department: string;
  email: string;
  phone: string;
};

export interface NotAudited {
  kind: 'NotAudited';
}
export interface PassedAudit {
  kind: 'PassedAudit';
  passed: true;
  documentId: number;
}
export interface FailedAudit {
  kind: 'FailedAudit';
  passed: false;
  failureReasons: AuditPoint[];
  notes: string;
  documentId: number;
  failureSensitivity: null | number;
}

export type AuditState = NotAudited | PassedAudit | FailedAudit;

export function isFailedAudit(audit: AuditState | undefined): audit is FailedAudit {
  return typeof audit !== 'undefined' && audit.kind === 'FailedAudit';
}

export function isPassedAudit(audit: AuditState | undefined): audit is PassedAudit {
  return typeof audit !== 'undefined' && audit.kind === 'PassedAudit';
}

export type MatchByClient = {
  clientId: number;
  count: number;
  daysAged: number;
};

export type QueueStatByClient = {
  clientId: number;
  count: number;
  skippedMatchCount?: number;
  uniqueDocumentCount?: number;
  daysAged: number;
};

export type LoanImportMapping = {
  id?: number;
  systemPropertyName: string;
  externalSourcePropertyName: string;
  clientId?: number;
};

export type OutreachDisposition = {
  id: number;
  stage: number;
  reason: string;
  noteTemplate: string | null;
  position: number;
};

export type Pod = {
  id: number;
  name: string;
};

export type Payment = {
  id: number;
  clientId: number;
  paymentAmt: number;
  datePaid: Date;
  checkNo: string;
};

export type ClientServiceLevel = {
  id: number;
  label: string;
};
