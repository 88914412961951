/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ClientUpdatedOnDocument as RowData } from './types';
import DataTable from '../../components/ui/DataTable/DataTable';
import Link from '@mui/material/Link';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

type Props = {
  reportData: RowData[];
};

const columns = [
  {
    field: 'documentId',
    title: 'Barcode',
    render: ({ documentId }: RowData) => (
      <Link target="_blank" href={`/documents/${documentId}`}>
        {documentId}
      </Link>
    ),
  },
  {
    field: 'originalClient',
    title: 'Original client',
  },
  {
    field: 'newClient',
    title: 'New client',
  },
  {
    field: 'updatedAt',
    title: 'Date updated',
    render: ({ updatedAt }: RowData) => (updatedAt ? new Date(updatedAt).toLocaleDateString() : ''),
  },
  {
    field: 'username',
    title: 'User',
  },
  {
    field: 'shipped',
    title: 'Shipped',
    render: ({ shipped }: RowData) =>
      shipped ? (
        <DoneIcon style={{ color: green[500] }} />
      ) : (
        <CloseIcon style={{ color: red[800] }} />
      ),
  },
];

const ClientUpdatedOnDocument = ({ reportData }: Props) => {
  return (
    <DataTable<RowData>
      title="Client updated on physical document"
      columns={columns}
      data={reportData}
      options={{
        maxBodyHeight: '500px',
        search: false,
        exportFileName: 'Client updated on physical document',
      }}
    />
  );
};

export default ClientUpdatedOnDocument;
