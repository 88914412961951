/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, SetStateAction } from 'react';
import Panel, { PanelHeader } from '../../../ui/Panel';
import Circle from '../../../ui/Circle';
import { IconType } from '../../../ui/Icon';
import colors from '../../../../styles/colors';
import makeStyles from '@mui/styles/makeStyles';
import { CompartmentData, DocumentException } from './SortClientDocuments';

const useStyles = makeStyles(theme => ({
  gridWrap: {
    border: `1px solid ${colors.grayLight}`,
    borderRadius: 4,
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: 16,
    overflow: 'hidden',
    padding: 8,
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      padding: 16,
      '& > div': {
        '&:last-child': { textAlign: 'center' },
      },
      '&:first-child': { borderRadius: '4px 4px 0 0' },
      '&:last-child': { borderRadius: '0 0 4px 4px' },
      '&:nth-child(odd)': {
        backgroundColor: colors.grayExLight,
      },
    },
  },
}));

type Props = {
  clientName: string;
  unsortedDocumentsCounts: CompartmentData[];
  setSelectedDocumentIdsReadyForSort: Dispatch<SetStateAction<number[]>>;
  setSelectedDocumentExceptions: Dispatch<SetStateAction<DocumentException[]>>;
};

const instructions = (label: string, isReadyToSort: boolean) => {
  if (['Sort Now', 'Sort Tomorrow', 'Pending Date'].includes(label)) {
    const emDash = '\u2014';
    return `${emDash} ${emDash}`;
  }

  return isReadyToSort ? 'SORT NOW' : 'HOLD';
};

const weekMonthLabel = (label: string, weekMonthStart: Date) => {
  if (!label.startsWith('Month ')) {
    return <span>{label}</span>;
  }

  return (
    <span>
      {label} <small>({weekMonthStart.getFullYear()})</small>
    </span>
  );
};

const ContainerCountsPanel = ({
  clientName,
  unsortedDocumentsCounts,
  setSelectedDocumentIdsReadyForSort,
  setSelectedDocumentExceptions,
}: Props) => {
  const classes = useStyles();

  return (
    <Panel styles={{ padding: 24, height: 'fit-content' }}>
      <PanelHeader
        text={clientName}
        iconComponent={<Circle icon={IconType.Client} styleoverrides={{ color: colors.blue }} />}
      />
      <div className={classes.gridWrap}>
        <div css={{ fontSize: 14, fontWeight: 600 }}>
          <div>Week/Month</div>
          <div>Document Count</div>
          <div>Exceptions</div>
          <div>Instructions</div>
        </div>
        {unsortedDocumentsCounts.map(
          ({
            compartmentId,
            label,
            documentIds,
            readyToSort,
            documentIdsReadyForSort,
            weekOrMonthStart,
            documentsWithExceptions,
          }) => (
            <div key={compartmentId}>
              <div>{weekMonthLabel(label, new Date(weekOrMonthStart))}</div>
              <div
                style={{ cursor: documentIdsReadyForSort.length > 0 ? 'pointer' : 'default' }}
                onClick={() => setSelectedDocumentIdsReadyForSort(documentIdsReadyForSort)}
              >
                {documentIdsReadyForSort.length}/{documentIds.length}
              </div>
              <div
                style={{ cursor: documentsWithExceptions.length > 0 ? 'pointer' : 'default' }}
                onClick={() => setSelectedDocumentExceptions(documentsWithExceptions)}
              >
                {documentsWithExceptions.length}/{documentIds.length}
              </div>
              <div>{instructions(label, readyToSort)}</div>
            </div>
          ),
        )}
      </div>
    </Panel>
  );
};

export default ContainerCountsPanel;
