/** @jsx jsx */
import React, { Fragment, ReactNode, useCallback, useState } from 'react';
import { jsx } from '@emotion/core';

type PrintingContext = (jsxToPrint: ReactNode) => void;
const PrintingContext = React.createContext({} as PrintingContext);

export function PrintingContextProvider({ children }) {
  const [jsxToPrint, setJsxToPrint] = useState();

  const print = useCallback(j => {
    setJsxToPrint(j);
    setTimeout(() => window.print(), 0);
  }, []);

  return (
    <Fragment>
      <PrintingContext.Provider value={print}>
        <div css={{ '@media print': { display: 'none' } }}>{children}</div>
        <div css={{ display: 'none', '@media print': { display: 'block' } }}>{jsxToPrint}</div>
      </PrintingContext.Provider>
    </Fragment>
  );
}

export default PrintingContext;
