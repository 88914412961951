/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormLabel,
  Link,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { produce } from 'immer';
import { Document, DocumentType } from '../globalTypes/objects';
import {
  isFailure,
  isLoading,
  isNotAsked,
  isSuccess,
  Loading,
  NotAsked,
  RemoteData,
  Success,
} from '../globalTypes/RemoteData';
import CoversheetPrint from './Documents/Imports/CoversheetPrint';
import { apiFetch, apiPost } from '../adalConfig';
import FileDropper from './ui/FileDropper';
import PrintingContext from '../PrintingContext';
import { fileToBase64, getDocTypeName, parseRequestError } from '../Utils';

interface Props {
  document: Document;
  onCure: (string) => void;
}

// types
interface HasType {
  type: string;
}

interface CanSubmit {
  submission: RemoteData<unknown, string>;
}

interface NoneChosen {
  type: 'NONE_CHOSEN';
}

interface NoCorrectionNeeded extends CanSubmit {
  type: 'NO_CORRECTION_NEEDED';
  reason: CorrectionReason | undefined;
  note: string;
  submission: RemoteData<CuredDocumentResponse, string>;
}

type Replacement = { type: 'REPLACEMENT' };
type Supportive = { type: 'SUPPORTIVE' };
type CorrectionMethod = NoneChosen | Replacement | Supportive;
interface ViaDocumentBase extends CanSubmit {
  type: 'VIA_EXISTING' | 'VIA_NEW';
  method: CorrectionMethod;
  submission: RemoteData<CuredDocumentResponse, string>;
}

interface ViaExistingDocument extends ViaDocumentBase {
  type: 'VIA_EXISTING';
  loanDocuments: Document[] | null;
  correctDocument: Document | undefined;
}

type FileFormat = { type: 'PHYSICAL' } | { type: 'DIGITAL' };
interface ViaNewDocument extends ViaDocumentBase {
  type: 'VIA_NEW';
  format: NoneChosen | FileFormat;
  correctDocument: File | undefined;
  documentType: DocumentType | undefined;
}

type CuredDocumentResponse =
  | {
      type: 'COVERSHEETS';
      originalDocCoversheet: Coversheet | null;
      newDocCoversheet: Coversheet | null;
    }
  | { type: 'NO_RESPONSE' };

type ViaDocument = ViaExistingDocument | ViaNewDocument;

type CureMethodChoice = NoCorrectionNeeded | ViaDocument;
type CuringState = NoneChosen | CureMethodChoice;

type CorrectionReason = {
  id: number;
  label: string;
};

function cureMethodChosen(cureState: CuringState): cureState is CureMethodChoice {
  return (
    cureState.type === 'VIA_EXISTING' ||
    cureState.type === 'VIA_NEW' ||
    cureState.type === 'NO_CORRECTION_NEEDED'
  );
}

type CureDocumentEndpoint =
  | '/Api/AccountReps/DocumentCorrectionNoCorrectionNeeded'
  | '/Api/AccountReps/DocumentCorrectionViaNewDocument'
  | '/Api/AccountReps/DocumentCorrectionViaExistingDocument';

interface ReqBase {
  endpoint: CureDocumentEndpoint;
}
interface NoCorrectionNeededReqBody extends ReqBase {
  documentId: number;
  note: string;
  reasonId: number;
  correctionType: 'CorrectionNotNeeded';
}
interface ViaExistingDocumentReqBody extends ReqBase {
  documentId: number;
  correctDocumentId: number;
  correctionType: 'Replacement' | 'Supportive';
}

interface ViaNewDocumentReqBody extends Omit<ViaExistingDocumentReqBody, 'correctDocumentId'> {
  isPhysical: boolean;
  file: File;
  documentType: DocumentType;
}

interface Coversheet {
  documentId: number;
  documentTypeDescription: string;
  clientName: string;
  borrowerName: string;
  dateDocumentDrawn: string;
  investorName: string;
}

type CureDocumentReq =
  | NoCorrectionNeededReqBody
  | ViaExistingDocumentReqBody
  | ViaNewDocumentReqBody;

type ReqBodyOrErrorMsg = CureDocumentReq | string;
function isValidReqBody(reqBody: ReqBodyOrErrorMsg): reqBody is CureDocumentReq {
  return typeof reqBody !== 'string';
}

async function saveCureDocument(reqBody: CureDocumentReq): Promise<CuredDocumentResponse> {
  const formData = new FormData();
  for (const [key, value] of Object.entries(reqBody)) {
    formData.append(key, value instanceof File ? await fileToBase64(value) : value);
  }

  return apiPost(reqBody.endpoint, formData).then(({ data }) => {
    if (!isEmpty(data?.newDocCoversheet) || !isEmpty(data?.originalDocCoversheet))
      return {
        type: 'COVERSHEETS',
        originalDocCoversheet: data?.originalDocCoversheet,
        newDocCoversheet: data?.newDocCoversheet,
      };
    return { type: 'NO_RESPONSE' };
  });
}

// type guards
const isNoneChosen = (state: HasType): state is NoneChosen => state.type === 'NONE_CHOSEN';
const isViaDocument = (state: CuringState): state is ViaDocument =>
  state.type === 'VIA_EXISTING' || state.type === 'VIA_NEW';

const isViaExisting = (state: CuringState): state is ViaExistingDocument =>
  state.type === 'VIA_EXISTING';

const isViaNew = (state: CuringState): state is ViaNewDocument => state.type === 'VIA_NEW';

const isSupportive = (state: CorrectionMethod): state is Supportive => state.type === 'SUPPORTIVE';

const noCorrectionNeeded = (val: HasType): val is NoCorrectionNeeded =>
  val.type === 'NO_CORRECTION_NEEDED';

const hasCorrectDocument = (state: CuringState) => isViaDocument(state) && state.correctDocument;

// constructors
const noneChosen: NoneChosen = { type: 'NONE_CHOSEN' };

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CureDocument({ document, onCure }: Props) {
  const [state, setState] = useState<CuringState>(noneChosen);
  const [noCorrectionReasons, setNoCorrectionReasons] = useState<CorrectionReason[]>(
    [] as CorrectionReason[],
  );
  const [, setShowErrorMsg] = useState<boolean>(false);
  const print = useContext(PrintingContext);

  const cureDocumentReqBody = useMemo<ReqBodyOrErrorMsg>(() => {
    switch (state.type) {
      case 'NONE_CHOSEN':
        return 'No method chosen';
      case 'NO_CORRECTION_NEEDED':
        if (
          typeof state.reason?.label === 'undefined' ||
          (state.reason?.label === 'Other' &&
            (!state.note.trim().length || state.note.length > 3000))
        )
          return 'Invalid reason';
        return {
          endpoint: '/Api/AccountReps/DocumentCorrectionNoCorrectionNeeded',
          correctionType: 'CorrectionNotNeeded',
          documentId: document.id,
          note: state.note,
          reasonId: state.reason.id,
        };
      case 'VIA_EXISTING':
        if (typeof state.correctDocument === 'undefined') return 'No correct document';
        if (state.method.type === 'NONE_CHOSEN')
          return 'Please choose if this a replacement or affidavit';
        return {
          endpoint: '/Api/AccountReps/DocumentCorrectionViaExistingDocument',
          correctionType: isSupportive(state.method) ? 'Supportive' : 'Replacement',
          documentId: document.id,
          correctDocumentId: state.correctDocument.id,
        };
      case 'VIA_NEW':
        if (typeof state.correctDocument === 'undefined') return 'No correct document';
        if (state.method.type === 'NONE_CHOSEN')
          return 'Please choose if this a replacement or affidavit';
        if (state.format.type === 'NONE_CHOSEN')
          return 'Please choose a format. Physical or Digital';
        if (!state.documentType) return 'Please choose a document type.';
        return {
          endpoint: '/Api/AccountReps/DocumentCorrectionViaNewDocument',
          correctionType: isSupportive(state.method) ? 'Supportive' : 'Replacement',
          documentId: document.id,
          file: state.correctDocument,
          isPhysical: state.format.type === 'PHYSICAL',
          documentType: state.documentType,
        };
      default:
        return 'No method chosen';
    }
  }, [document, state]);

  useEffect(() => {
    if (
      (isViaDocument(state) || noCorrectionNeeded(state)) &&
      isSuccess(state.submission) &&
      state.submission.data.type === 'COVERSHEETS'
    ) {
      const coversheets = [
        state.submission.data.originalDocCoversheet,
        state.submission.data.newDocCoversheet,
      ].filter((coversheet): coversheet is Coversheet => coversheet !== null);

      print(
        <Fragment>
          {coversheets.map(coversheet => (
            <CoversheetPrint key={coversheet.documentId} {...coversheet} />
          ))}
        </Fragment>,
      );
    }
  }, [print, state]);

  useEffect(() => {
    // make sure we have a valid request body, or dont submit
    if (!isValidReqBody(cureDocumentReqBody)) return;
    if (!cureMethodChosen(state) || !isLoading(state.submission)) return;

    saveCureDocument(cureDocumentReqBody)
      .then(resp => {
        setState(
          produce(state, draft => {
            draft.submission = Success(resp);
          }),
        );
        if (resp.type === 'COVERSHEETS') onCure('PHYSICAL');
        else if ((isViaNew(state) && state.format.type === 'DIGITAL') || isViaExisting(state)) {
          if (state.method.type === 'REPLACEMENT') {
            onCure('REPLACEMENT');
          } else {
            onCure('DIGITAL');
          }
        } else {
          onCure('NOTE');
        }
      })
      .catch(error => {
        const firstError = parseRequestError(error)[0];
        onCure(`ERROR! ${firstError}`);
      });
  }, [document, state, cureDocumentReqBody]);

  // get other docs of this loan
  useEffect(() => {
    if (isViaExisting(state) && document.loanId && !state.loanDocuments) {
      apiFetch<Document[] | null>(`/Api/AccountReps/LoanDocs/${document.id}`).then(({ data }) =>
        setState(
          produce(state, draft => {
            draft.loanDocuments = data;
          }),
        ),
      );
    }
  }, [state, document.loanId, document.id]);

  async function getCorrectionReasons() {
    const { data } = await apiFetch<CorrectionReason[]>(`/Api/AccountReps/CorrectionReasons`);
    return data;
  }

  useEffect(() => {
    getCorrectionReasons().then(data => setNoCorrectionReasons(data));
  }, []);

  // update state functions
  function chooseNoCorrectionNeeded() {
    setState({
      type: 'NO_CORRECTION_NEEDED',
      note: '',
      reason: {} as CorrectionReason,
      submission: NotAsked,
    });
  }

  function chooseUploadDocument() {
    setState({
      type: 'VIA_NEW',
      submission: NotAsked,
      correctDocument: undefined,
      method: noneChosen,
      format: noneChosen,
      documentType: undefined,
    });
  }

  function chooseExistingDocument() {
    setState({
      type: 'VIA_EXISTING',
      loanDocuments: null,
      correctDocument: undefined,
      submission: NotAsked,
      method: noneChosen,
    });
  }

  function supportiveChosen(currentState: ViaDocument) {
    setState(
      produce(currentState, draft => {
        draft.method = { type: 'SUPPORTIVE' };
      }),
    );
  }
  function viaReplacementChosen(currentState: ViaDocument) {
    setState(
      produce(currentState, draft => {
        draft.method = { type: 'REPLACEMENT' };
        if (isViaNew(draft)) {
          draft.documentType = document.documentType ?? DocumentType.Unknown;
        }
      }),
    );
  }

  function updateNoCorrectionNeededNote(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentState: NoCorrectionNeeded,
  ) {
    const { value } = e.target;
    setState(
      produce(currentState, draft => {
        draft.note = value;
      }),
    );
  }

  function updateNoCorrectionNeededReason(e, reason, currentState: NoCorrectionNeeded) {
    setState(
      produce(currentState, draft => {
        if (reason.label !== 'Other') {
          draft.note = '';
        }
        draft.reason = reason;
      }),
    );
  }

  function submit(currentState: CureMethodChoice) {
    if (!isValidReqBody(cureDocumentReqBody)) {
      setShowErrorMsg(true);
    } else {
      setState(
        produce(currentState, draft => {
          draft.submission = Loading;
        }),
      );
    }
  }

  function chooseCorrectDoc(currentState: ViaExistingDocument, doc: Document) {
    setState(
      produce(currentState, draft => {
        draft.correctDocument = doc;
        doc.documentType === DocumentType.AffidavitOfCorrection ||
        doc.documentType === DocumentType.Endorsement ||
        (document.documentType !== DocumentType.Policy && document.hardCopy)
          ? (draft.method = { type: 'SUPPORTIVE' })
          : (draft.method = { type: 'REPLACEMENT' });
      }),
    );
  }

  function newFileInputChange(file: File | undefined, currentState: ViaNewDocument) {
    setState(
      produce(currentState, draft => {
        draft.correctDocument = file;
      }),
    );
  }

  function fileFormatChosen(currentState: ViaNewDocument, fileFormat: FileFormat) {
    setState(
      produce(currentState, draft => {
        draft.format = fileFormat;
      }),
    );
  }

  return (
    <Fragment>
      {isNoneChosen(state) ? (
        <List>
          <Divider />
          <ListItem button onClick={chooseNoCorrectionNeeded}>
            No Correction Needed
          </ListItem>
          <Divider />
          <ListItem button onClick={chooseUploadDocument}>
            Correct with New Document
          </ListItem>
          <Divider />
          {document.loanId && (
            <Fragment>
              <ListItem button onClick={chooseExistingDocument}>
                Correct with Existing Document
              </ListItem>
              <Divider />
            </Fragment>
          )}
        </List>
      ) : null}

      {noCorrectionNeeded(state) && (
        <div css={{ padding: 24 }}>
          <div css={{ marginBottom: 12 }}>
            <Autocomplete
              options={noCorrectionReasons}
              getOptionLabel={option => (typeof option.label !== 'string' ? '' : option.label)}
              onChange={(e, reason) => updateNoCorrectionNeededReason(e, reason, state)}
              value={state.reason}
              style={{ width: 300 }}
              autoHighlight
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label="No correction needed reason"
                  variant="outlined"
                />
              )}
            />
          </div>
          <div>
            {state.reason?.label === 'Other' && (
              <TextField
                label="Note"
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                value={state.note}
                disabled={state.reason?.label !== 'Other'}
                inputRef={input => input && input.focus()}
                onChange={e => updateNoCorrectionNeededNote(e, state)}
                error={state.note.length > 3000}
                helperText={state.note.length > 3000 && 'Note exceeds 3000 character limit.'}
              />
            )}
          </div>
        </div>
      )}

      {isViaExisting(state) && (
        <div css={{ padding: '8px 48px 8px 24px' }}>
          {state.loanDocuments?.length ? (
            <RadioGroup>
              <FormLabel>Choose a Document from the Loan</FormLabel>
              <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                {state.loanDocuments?.map(doc => (
                  <FormControlLabel
                    key={doc.id}
                    label={
                      <Fragment>
                        <Link target="_blank" href={`/documents/${doc.id}`}>
                          {doc.id}
                        </Link>
                        <span> {doc.documentType && getDocTypeName(doc.documentType)}</span>
                      </Fragment>
                    }
                    control={<Radio color="primary" />}
                    onClick={() => chooseCorrectDoc(state, doc)}
                    checked={state.correctDocument === doc}
                  />
                ))}
              </div>
            </RadioGroup>
          ) : (
            <div>There are no other applicable documents attached to this loan.</div>
          )}
        </div>
      )}

      {isViaNew(state) && (
        <div css={{ padding: 14 }}>
          <FileDropper
            onChange={e => newFileInputChange(e, state)}
            acceptableFileTypes=".pdf"
            file={state.correctDocument}
            styles={{ width: '400px', height: '400px' }}
          />
          {state.correctDocument && (
            <RadioGroup css={{ padding: '40px 10px 16px' }}>
              <FormLabel>Document Format</FormLabel>
              <FormControlLabel
                checked={state.format.type === 'PHYSICAL'}
                onClick={() => fileFormatChosen(state, { type: 'PHYSICAL' })}
                control={<Radio color="primary" />}
                label="Physical"
              />
              <FormControlLabel
                checked={state.format.type === 'DIGITAL'}
                onClick={() => fileFormatChosen(state, { type: 'DIGITAL' })}
                control={<Radio color="primary" />}
                label="Digital"
              />
            </RadioGroup>
          )}
        </div>
      )}

      {isViaNew(state) && hasCorrectDocument(state) && (
        <div css={{ paddingLeft: 24 }}>
          <RadioGroup>
            <FormLabel>Curing Type</FormLabel>
            <FormControlLabel
              checked={state.method.type === 'REPLACEMENT'}
              onClick={() => viaReplacementChosen(state)}
              control={<Radio color="primary" />}
              label="Replacement"
            />
            <FormControlLabel
              checked={state.method.type === 'SUPPORTIVE'}
              onClick={() => supportiveChosen(state)}
              control={<Radio color="primary" />}
              label={
                <div>
                  Supportive Document{' '}
                  <span css={{ color: 'gray' }}>(i.e.: Affidavit or Endorsement)</span>
                </div>
              }
            />
          </RadioGroup>
        </div>
      )}

      {isViaNew(state) && hasCorrectDocument(state) && isSupportive(state.method) && (
        <RadioGroup css={{ paddingLeft: 24, paddingTop: 30 }}>
          <FormLabel>Choose the Supportive Document&apos;s DocType</FormLabel>
          <FormControlLabel
            checked={state.documentType === DocumentType.AffidavitOfCorrection}
            onClick={() =>
              setState(
                produce(state, draft => {
                  draft.documentType = DocumentType.AffidavitOfCorrection;
                }),
              )
            }
            control={<Radio color="primary" />}
            label="Affidavit of Correction"
          />
          <FormControlLabel
            checked={state.documentType === DocumentType.Endorsement}
            onClick={() =>
              setState(
                produce(state, draft => {
                  draft.documentType = DocumentType.Endorsement;
                }),
              )
            }
            control={<Radio color="primary" />}
            label="Endorsement"
          />
        </RadioGroup>
      )}

      {cureMethodChosen(state) && (
        <div css={{ display: 'flex', justifyContent: 'center', padding: 24 }}>
          <div css={{ display: 'flex', justifyContent: 'space-between', width: 150 }}>
            {(isNotAsked(state.submission) || isFailure(state.submission)) && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setState(noneChosen);
                }}
              >
                Back
              </Button>
            )}
            {(isNotAsked(state.submission) || isFailure(state.submission)) && (
              <Button
                variant="contained"
                color="primary"
                disabled={!isValidReqBody(cureDocumentReqBody)}
                onClick={() => submit(state)}
              >
                Cure
              </Button>
            )}
          </div>
          {isLoading(state.submission) && (
            <CircularProgress css={{ position: 'absolute', top: '46.5%', left: '46.5%' }} />
          )}
          <Snackbar open={isFailure(state.submission)} autoHideDuration={6000}>
            <Alert severity="error">Oops! There was an error. Doc was not cured.</Alert>
          </Snackbar>
        </div>
      )}
    </Fragment>
  );
}
