/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { apiFetch, apiPost } from '../../../../adalConfig';
import produce from 'immer';
import { SortResponse } from '../SortDocumentPanel';
import { useToaster } from '../../../../Hooks/toasters';
import { useClients } from '../../../../Hooks/useClients';
import ContainerCountsPanel from './ContainerCountsPanel';
import ScanForSort from './ScanForSort';
import { Client } from '../../../../globalTypes/objects';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import CreateIcon from '@mui/icons-material/Create';
import PrintIcon from '@mui/icons-material/Print';
import useLabelPrinters from '../../../../Hooks/useLabelPrinters';
import PrinterModal from './PrinterModal';
import Tooltip from '@mui/material/Tooltip';
import UIfx from 'uifx';
// @ts-ignore
import ErrorEffect from '../../ErrorEffect.wav';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '5fr 10fr',
    gap: '24px',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    minHeight: '200px',
    maxHeight: '90%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    textAlign: 'center',
  },
  iconsContainer: {
    position: 'absolute',
    top: '0px',
    right: '10px',
    display: 'flex',
    gap: '5px',
  },
  editPrinter: {
    display: 'flex',
    gap: '4px',
  },
}));

export type DocumentException = {
  documentId: number;
  isInvestorException: boolean;
  isFlagged: boolean;
};

export type CompartmentData = {
  compartmentId: number;
  clientId: number;
  label: string;
  documentIds: number[];
  documentIdsReadyForSort: number[];
  readyToSort: boolean;
  weekOrMonthStart: string;
  documentsWithExceptions: DocumentException[];
};

const getUnsortedDocumentsCount = async (clientId: number) => {
  const { data } = await apiFetch<CompartmentData[]>(
    `/api/documents/data-for-unsorted-documents?clientId=${clientId}`,
  );
  return data;
};

const removeDocumentFromCounts = (compartmentsData: CompartmentData[], documentId: number) => {
  return produce(compartmentsData, (draft: CompartmentData[]) => {
    draft.forEach(compartmentData => {
      compartmentData.documentIds = compartmentData.documentIds.filter(d => d !== documentId);
      compartmentData.documentIdsReadyForSort = compartmentData.documentIdsReadyForSort.filter(
        d => d !== documentId,
      );
      compartmentData.documentsWithExceptions = compartmentData.documentsWithExceptions.filter(
        d => d.documentId !== documentId,
      );
    });
  });
};

const updateCompartmentsIfMisplaced = (
  compartmentsData: CompartmentData[],
  documentId: number,
  newlyAssignedCompartmentId?: number,
) => {
  if (newlyAssignedCompartmentId === undefined) {
    return compartmentsData;
  }

  return produce(compartmentsData, (draft: CompartmentData[]) => {
    draft.forEach(compartmentData => {
      const inCurrentCompartment = compartmentData.documentIds.includes(documentId);
      const missingFromCompartment =
        compartmentData.compartmentId === newlyAssignedCompartmentId && !inCurrentCompartment;
      const inWrongCompartment =
        compartmentData.compartmentId !== newlyAssignedCompartmentId && inCurrentCompartment;

      if (missingFromCompartment) {
        compartmentData.documentIds.push(documentId);
      } else if (inWrongCompartment) {
        const index = compartmentData.documentIds.indexOf(documentId);
        compartmentData.documentIds.splice(index, 1);
      }
    });
  });
};

const errorSound = new UIfx({
  asset: ErrorEffect,
});

const SortClientDocuments = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [compartmentsData, setCompartmentsData] = useState<CompartmentData[]>([]);
  const [sortResponse, setSortResponse] = useState<SortResponse>({} as SortResponse);
  const [selectedDocumentIdsReadyForSort, setSelectedDocumentIdsReadyForSort] = useState<number[]>(
    [],
  );
  const [selectedDocumentExceptions, setSelectedDocumentExceptions] = useState<DocumentException[]>(
    [],
  );
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isProcessingSort, setIsProcessingSort] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrintPaused, setIsPrintPaused] = useState(false);

  const clients = useClients();
  const { printers, printer, setPrinter, setPrinterId } = useLabelPrinters('sort.label.printer');
  const { errorToaster, infoToaster } = useToaster();
  const classes = useStyles();

  useEffect(() => {
    if (!selectedClient) {
      return;
    }

    getUnsortedDocumentsCount(selectedClient.id)
      .then(setCompartmentsData)
      .catch(e => {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      });
  }, [selectedClient]);

  const handleDocumentIdChange = async (documentId: string) => {
    if (!documentId) {
      return;
    }

    setScannedBarcode(documentId);
    try {
      setIsProcessingSort(true);
      const { data } = await apiPost<SortResponse>('/api/documents/sortDoc', {
        documentId,
        isNewSort: true,
        printerId: isPrintPaused ? 0 : printer?.id,
      });

      if (data.requiresPrinterId && !isPrintPaused) {
        setIsModalOpen(true);
      }

      setSortResponse(data);

      if (data.isSorted) {
        const filteredData = removeDocumentFromCounts(compartmentsData, parseInt(documentId));
        setCompartmentsData(filteredData);
        return;
      }

      const updatedCompartmentsData = updateCompartmentsIfMisplaced(
        compartmentsData,
        parseInt(documentId),
        data.compartmentId,
      );
      setCompartmentsData(updatedCompartmentsData);

      if (
        data.sortDocumentLocation !== null &&
        selectedClient?.company !== data.sortDocumentLocation.client
      ) {
        errorSound.play();
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsProcessingSort(false);
    }
  };

  const printLabel = async (documentId: number, printerId: number) => {
    if (isPrintPaused) {
      return;
    }

    try {
      await apiPost(`/api/documents/print-label`, {
        documentId,
        printerId: isPrintPaused ? 0 : printerId,
      });
    } catch (e) {
      if (!e.response.data?.errors) {
        errorToaster(e.message);
        return;
      }

      errorToaster(
        <Fragment>
          Cannot print!
          {e.response.data.errors.map((error, i) => (
            <p key={i}>{error.message}</p>
          ))}
        </Fragment>,
      );
    }
  };

  const selectPrinter = async (isPrintPaused: boolean) => {
    setIsPrintPaused(isPrintPaused);

    if (isPrintPaused) {
      setIsModalOpen(false);
      return;
    }

    if (!printer) {
      return;
    }

    setPrinterId(printer.id);
    setIsModalOpen(false);

    if (sortResponse.requiresPrinterId) {
      await printLabel(parseInt(scannedBarcode), printer.id);
    }
  };

  return (
    <Fragment>
      <div className="mt3 relative">
        <div className={classes.iconsContainer}>
          {scannedBarcode.length > 0 && printer !== null && !isPrintPaused && (
            <div>
              <Tooltip title={`Reprint label for barcode ${scannedBarcode}`}>
                <PrintIcon
                  color="primary"
                  onClick={async () => {
                    infoToaster('Reprinting...');
                    await printLabel(parseInt(scannedBarcode), printer.id);
                  }}
                />
              </Tooltip>
            </div>
          )}
          {(printer !== null || isPrintPaused) && !isModalOpen && (
            <div className={classes.editPrinter}>
              <CreateIcon color="action" onClick={() => setIsModalOpen(true)} />
              {isPrintPaused ? 'Printing paused' : printer!.name}
            </div>
          )}
        </div>
        <div className="mb3">
          <Autocomplete
            options={clients}
            getOptionLabel={(option: Client) => option?.company || ''}
            onChange={(event: any, newValue: Client | null) => setSelectedClient(newValue)}
            onOpen={() => setIsClientDropdownOpen(true)}
            onClose={() => setIsClientDropdownOpen(false)}
            blurOnSelect={true}
            value={selectedClient}
            style={{ width: 300 }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Choose a client" variant="outlined" />
            )}
          />
        </div>
        {selectedClient && (
          <div className={classes.container}>
            <ContainerCountsPanel
              clientName={selectedClient?.company || ''}
              unsortedDocumentsCounts={compartmentsData}
              setSelectedDocumentIdsReadyForSort={setSelectedDocumentIdsReadyForSort}
              setSelectedDocumentExceptions={setSelectedDocumentExceptions}
            />
            <ScanForSort
              clientName={selectedClient?.company || ''}
              sortResponse={sortResponse}
              onDocumentIdChange={handleDocumentIdChange}
              keepFocus={
                !selectedDocumentIdsReadyForSort.length &&
                !selectedDocumentExceptions.length &&
                !isClientDropdownOpen &&
                !isModalOpen
              }
              isProcessingSort={isProcessingSort}
            />
          </div>
        )}
      </div>

      <PrinterModal
        printer={printer}
        setPrinter={setPrinter}
        printers={printers}
        isModalOpen={isModalOpen}
        selectPrinter={selectPrinter}
        isPrintPaused={isPrintPaused}
      />

      <Modal
        open={!!selectedDocumentIdsReadyForSort.length}
        onClose={() => setSelectedDocumentIdsReadyForSort([])}
      >
        <div className={classes.paper}>
          <h2 className="m2">Barcodes ready for sort</h2>
          <div>
            {selectedDocumentIdsReadyForSort.map(documentId => (
              <p style={{ userSelect: 'none' }} key={documentId}>
                {documentId}
              </p>
            ))}
          </div>
        </div>
      </Modal>

      <Modal
        open={!!selectedDocumentExceptions.length}
        onClose={() => setSelectedDocumentExceptions([])}
      >
        <div className={classes.paper}>
          <h2 className="m2">Document Exceptions</h2>
          <div>
            {selectedDocumentExceptions.map(({ documentId, isInvestorException, isFlagged }) => (
              <p style={{ userSelect: 'none' }} key={documentId}>
                <span>{documentId}</span>
                {exception(isInvestorException, 'Investor Exception')}
                {exception(isFlagged, 'Flagged')}
              </p>
            ))}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

const exception = (isException: boolean, exceptionType: 'Investor Exception' | 'Flagged') => {
  if (!isException) {
    return <Fragment />;
  }

  return <span style={{ padding: '8px', color: 'red', fontStyle: 'italic' }}>{exceptionType}</span>;
};

export default SortClientDocuments;
